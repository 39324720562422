<template>
  <div class="jw-author jw-author--wrap">
    <v-avatar v-if="avatarOn" class="jw-author__thumbs" :size="props.size" :class="avatarBorder && 'border'" @click="onClickAvatar">
      <!-- 사용자가 차단상태일때 표시될이미지 -->
      <VImg v-if="isBlock" src="~/assets/images/block_profile.svg" @click.stop="onClickBlockProfile" />
      <!-- 사용자 이미지가 있을경우 -->
      <VImg v-else-if="!!props.imgSrc" :src="props.imgSrc" />
      <!-- 사용자 이미지가 없을경우 사용자명 앞 한글자만 표시 -->
      <span v-else-if="!!props.memName" class="initial">
        <!-- {{ props.memName.substring(0, 1).toLocaleUpperCase('en-US') }} -->
        {{ displayName(props.memName) }}
      </span>
    </v-avatar>
    <template v-if="!props.avatarOnly">
      <div v-if="avatarOn && !!props.emotion" class="jw-author__emotion">
        <img v-if="props.emotion === 'usually'" src="~/assets/images/emotion_usually.png" alt="평범해요" />
        <img v-else-if="props.emotion === 'pray'" src="~/assets/images/emotion_pray.png" alt="기도할래요" />
        <img v-else-if="props.emotion === 'tired'" src="~/assets/images/emotion_tired.png" alt="힘들어오" />
        <img v-else-if="props.emotion === 'happy'" src="~/assets/images/emotion_happy.png" alt="행복해요" />
        <img v-else-if="props.emotion === 'thanks'" src="~/assets/images/emotion_thanks.png" alt="감사해요" />
        <img v-else-if="props.emotion === 'impress'" src="~/assets/images/emotion_impress.png" alt="감동이에요" />
        <img v-else-if="props.emotion === 'awkward'" src="~/assets/images/emotion_awkward.png" alt="난처해요" />
      </div>

      <div v-if="!!props.memName && props.kind !== 'dailyNoteList'" class="jw-author__name mx-1">
        {{ isBlock ? "차단사용자" : props.memName }}
      </div>
      <div v-if="fromRoomCmtSheet" class="jw-author__name mx-1">({{ props.roomName }})</div>

      <div v-if="isRoomListDesc" class="jw-author__comment mr-1">
        {{ dateActor(props.diarySaveLDate) }}
      </div>
      <!-- <div v-if="!!writer" class="jw-author__name mx-1">{{ isBlock ? '차단사용자' : writer }}</div> -->
      <div v-if="props.kind === 'detailList' && !!props.date" class="jw-author__date mx-1">{{ date }}</div>
      <div v-if="!!props.postCount" class="jw-author__comment ml-2">
        <VIcon class="mr-1">jwIcons:svg/comment</VIcon>
        {{ props.postCount }}
      </div>
      <!-- <div v-if="!!props.likeItCount" class="jw-author__like ml-1"> -->
      <!-- <div class="jw-author__like ml-2" v-if="props.isHeader"> -->
      <div class="jw-author__like ml-2" v-if="showLike">
        <VIcon class="mr-1">jwIcons:svg/heart</VIcon>
        {{ props.likeItCount || props.amenCount || 0 }}
      </div>
    </template>

    <!-- 유저 정보 팝업 -->
    <jwAuthorModal :modalOpen="userDetail.dialogOpen" @modalClose="userDetail.dialogOpen = false" v-bind="toModalProps" />
  </div>
</template>

<script setup>
import { useDailyStore } from "~/store/daily";
import { useAuthStore } from "~/store/auth";
import { useTodayStore } from "~/store/today";
import { useCommonStore } from "~/store/common";
import { useMemberStore } from "~/store/member";
import { useDisplay } from "vuetify";
import { storeToRefs } from "pinia";
import { displayName } from "~/utils/utils";
import jwAuthorModal from "./jwAuthorModal.vue";
import dayjs from "dayjs";

const { smAndDown } = useDisplay();
const isMobile = computed(() => smAndDown.value);
const store = useDailyStore();

const authStore = useAuthStore();

const loginStatus = computed(() => authStore.loginStatus);

//const { loginStatus } = storeToRefs(useAuthStore());

const todayStore = useTodayStore();
const columnDet = computed(() => todayStore.state.columnDet);

const commonStore = useCommonStore();
const appHeader = computed(() => commonStore.appHeader);

const memberStore = useMemberStore();

const route = useRoute();

definePageMeta({
  name: "JwAuthor",
});

const props = defineProps({
  // 사용자 차단 api시 사용할 사용 고유값도, 좋아요 api를 위한 해당 글(컨텐츠) 고유값도 들어가야될것 같아요.
  /* isBlock: {
    type: Boolean,
    default: false
  }, */
  isHeader: {
    type: Boolean,
    default: false,
  },
  imgSrc: {
    type: [String, Boolean],
  },
  size: {
    type: [String, Number],
    default: 20,
  },
  name: {
    type: [String, Boolean],
  },
  memName: {
    type: String,
  },
  writer: {
    type: String,
  },
  desc: {
    type: String, // 사용자 프로필 문구? 'figma : `사용자 썸네일 클릭시` = 항상 주님의 마음을...'
  },
  date: {
    type: String,
  },
  commentCount: {
    type: [String, Number],
  },
  postCount: {
    type: [String, Number],
  },
  likeItCount: {
    type: [String, Number],
  },
  amenCount: {
    type: [String, Number],
  },
  id: {
    type: String,
  },
  avatarOnly: {
    type: Boolean,
    default: false,
  },
  kind: {
    type: String,
  },
  //신고용 diaryCd, gubun 추가
  diaryCd: {
    type: String,
  },
  gubun: {
    type: String,
  },
  //차단하기용 memCd 추가
  memCd: {
    type: String,
  },
  author: {
    type: Object,
  },
  blocking: {
    type: [Number, String],
  },
  emotion: {
    type: String,
    default: null,
  },
  diarySaveLDate: String,
  //showLike: Boolean,
  showLike: {
    type: Boolean,
    default: true,
  },
  fromRoomCmtSheet: Boolean,
  roomName: String,
});

let toModalProps = reactive({ ...props });

/* watch(
  () => toModalProps,
  v => {

  },
  { deep: true, immediate: true }
); */

const avatarOn = computed(() => {
  const { memName, imgSrc } = props;
  /*
   */

  if (!!memName || imgSrc) {
    //console.log("return true!!!!!!");
    return true;
  } else return false;
});

watch(
  () => props,
  (v) => {
    //
    //console.log("JwAuthor props :: ", props);
  },
  { deep: true, immediate: true }
);

const avatarBorder = computed(() => {
  //const { memName, imgSrc, isBlock } = props;
  const { memName, imgSrc } = props;
  if (!!memName || !imgSrc || isBlock.value) return true;
  else return false;
});

const isBlock = computed(() => {
  if (!isEmpty(props.blocking) && [1, "1", 3, "3"].includes(props.blocking)) {
    return true;
  } else {
    return false;
  }
});

const isRoomListDesc = computed(() => {
  if (route.name == "room-roomCd") return true;
  else return false;
});

//

/* if (props.kind === 'rcmd') {

}
 */

// 다이알로그 관련
// 사용자 썸네일 클릭 관련
const userDetail = reactive({
  dialogOpen: false,
});

async function getArticleMemberInfo() {
  const { data: memRes = {} } = await commonStore.getMemInfoActor({ memCd: props.memCd });

  return (toModalProps = { ...columnDet.value, ...props, gubun: "t_column", ...memRes.result });
}

async function onClickAvatar() {
  if (loginStatus.value) {
    if (props.isBlock) {
      // 우선 확인창 만 넣어두었습니다.
      if (!confirm("차단한 사용자의 프로필과 모든 글이 노출 됩니다.")) {
        return false;
      } else {
        store.deactiveBlock(props.id);
      }
    } else {
      if (route.name === "section-id" && ["column", "rcmd"].includes(route.params.section)) {
        await getArticleMemberInfo();
        userDetail.dialogOpen = true;
        return;
      }

      if (!props.memCd) await authStore.chkLoginStatusActor();

      const { data: memRes = {} } = await commonStore.getMemInfoActor({ memCd: props.memCd || authStore.userInfo.memCd });

      toModalProps = { ...props, ...memRes.result };

      userDetail.dialogOpen = true;
    }
  } else {
    /* const { data: memRes = {} } = await commonStore.getMemInfoActor({ memCd: props.memCd });
    toModalProps = { ...props, ...memRes.result };

    userDetail.dialogOpen = true; */
    return false;
  }
}

async function onClickBlockProfile() {
  // 차단해제 얼럿을 띄운다.

  commonStore.setConfirmDialog({
    open: true,
    title: "알림",
    text: `차단을 해제하시겠습니까?`,
    positive: {
      name: "예",
      actor: async () => {
        await memberStore.blockingUserActor({ memCd: props.memCd, delYN: "Y" });
        location.reload(true);
      },
    },
    negative: {
      name: "아니오",
    },
  });
}

function dateActor(saveLDate) {
  //return dayjs(saveLDate).format("YYYY-MM-DD");
  if (isEmpty(saveLDate)) return "";
  // 현재 날짜와 비교할 날짜 객체를 생성합니다.

  const inputDate = dayjs(saveLDate, "YYYYMMDDHHmmss");
  const now = dayjs();

  // 오늘 날짜와 비교합니다.
  if (inputDate.isSame(now, "day")) {
    // 오늘 날짜와 일치하면 HH:mm 형식으로 반환합니다.
    return inputDate.format("HH:mm");
  } else if (inputDate.isSame(now.subtract(1, "day"), "day")) {
    // 어제 날짜와 일치하면 '어제'를 반환합니다.
    return "어제";
  } else if (inputDate.isBefore(now.subtract(1, "day")) && inputDate.isSame(now, "year")) {
    // 어제보다 이전이고 올해 날짜이면 MM.DD 형식으로 반환합니다.
    return inputDate.format("MM.DD");
  } else {
    // 올해 날짜와 일치하지 않으면 YY.MM.DD 형식으로 반환합니다.
    return inputDate.format("YY.MM.DD");
  }
}
</script>
